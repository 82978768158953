import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const TermsPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={configs.app_keywords} />
    <section class="legalPage">
      <h1 class="legal-title">Términos y condiciones</h1>
      <p class="legal-subtitle">Última actualización: Diciembre de 2019</p>
      <p class="legal-subtitle">
        Los presentes Términos y Condiciones de Uso del Servicio ("Condiciones
        de Servicio" ) regulan el acceso y uso por parte del usuario de la
        aplicación "Vigilante Renfe" y del sitio web{' '}
        <a
          href="https://vigilanterenfe.es"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://vigilanterenfe.es
        </a>{' '}
        además de cualquier otra aplicación móvil, interface de programación de
        aplicaciones u otros servicios ofrecidos, incluida cualquiera de sus
        filiales, así como los servicios ofrecidos a través de terceros que
        integren funcionalidades ofrecidas.
      </p>
      <p class="legal-subtitle">
        El acceso a este Sitio Web y/o su utilización en cualquier forma le
        otorga la calificación de Usuario ("Usuario" o "Usuarios").
      </p>
      <p class="legal-subtitle">
        Al utilizar el Sitio o los Servicios, el Usuario acepta las presentes
        Condiciones de Servicio y nuestra Política de Privacidad, por lo que le
        rogamos los lea con atención. Si no está conforme con las Condiciones de
        Servicio y/o nuestra Política de Privacidad, no utilice nuestros
        Servicios.
      </p>
      <ol>
        <h3>
          <li>ELEGIBILIDAD</li>
        </h3>
        <p>
          Para acceder y utilizar los Servicios, el Usuario debe, como mínimo,
          haber cumplido la mayoría de edad establecida por su estado o país de
          residencia Si el usuario no ha cumplido la mayoría de edad de su
          estado o país de residencia, es decir, es menor de edad, los padres o
          su tutor legal deben consentir en su nombre, en la medida en que éstos
          estén de acuerdo con las Condiciones de Servicio y nuestra Política de
          Privacidad.
        </p>
        <p>
          Para los usuarios que se encuentren en la Unión Europea, el Usuario
          debe tener al menos 16 años para acceder y/o utilizar los Servicios.
          Si el usuario es menor de 16 años debe contar con el consentimiento de
          su tutor legal y solo en la medida en que el tutor legal está de
          acuerdo con estas Condiciones de Servicio para acceder y/o utilizar
          los Servicios. En caso de que la legislación nacional aplicable del
          usuario lo permita, el usuario menor de 16 años puede acceder al
          servicio, siempre que esta no sea inferior a 13 años.
        </p>
        <h3>
          <li>TÉRMINOS ADICIONALES; REGLAS DE LA APLICACIÓN</li>
        </h3>
        <p>
          Algunos de nuestros Servicios pueden tener términos y condiciones
          adicionales ("Términos Adicionales"). Cuando se apliquen Términos
          Adicionales a un Servicio, éstos estarán disponibles para que el
          Usuario los pueda leer durante el uso del mismo. En todo caso, se
          entenderá que, al usar el Servicio, el Usuario acepta los Términos
          Adicionales sin reserva alguna.
        </p>
        <h3>
          <li>USO APROPIADO DEL SITIO Y DE LOS SERVICIOS</li>
        </h3>
        <p>
          El Usuario es responsable del uso que pueda hacer del Sitio y de los
          Servicios tanto por sí mismo, como el efectuado a través de su cuenta,
          . Nuestro objetivo es crear una experiencia positiva, útil y segura
          para el Usuario. Por ello, se prohíben ciertas conductas que pueden
          ser perjudiciales para otros Usuarios o para Nosotros. Cuando utiliza
          el Sitio o los Servicios, el usuario no puede:
        </p>
        <ul>
          <li>
            <p>violar cualquier ley o reglamento;</p>
          </li>
          <li>
            <p>
              violar, infringir o apropiarse indebidamente de la propiedad
              intelectual, privacidad, publicidad u otros derechos legales de
              terceros;
            </p>
          </li>
          <li>
            <p>
              comunicar, transmitir, cargar al Sitio o en los Servicios o, de
              cualquier otro modo, poner a disposición cualquier contenido que,
              de modo razonable, sea ilícito, dañino, hostigador, difamatorio,
              de naturaleza pornográfica, obscena o indecente, invasivo a la
              privacidad de otra persona, aborrecible, proclive a incentivar el
              odio racial o étnico, o, contenido de cualquier otra índole que
              pudiera ser perjudicial para los derechos propios y/o de terceros.
            </p>
          </li>
          <li>
            <p>
              enviar comunicaciones publicitarias o comerciales no solicitadas o
              no autorizadas, como spam; falsificar cualquier encabezado del
              paquete del Protocolo de Control de Transmisión (TCP, por sus
              siglas en inglés) o la dirección de IP o cualquier parte de la
              información de encabezado en cualquier correo electrónico o
              publicación; así como utilizar los Servicios para enviar
              información identificadora de la fuente que esté alterada o sea
              falsa o engañosa a través de cualquier medio;
            </p>
          </li>
          <li>
            <p>
              participar en actividades de spidering o harvesting, o participar
              en el uso de software, lo que incluye, a título enunciativo, el
              spyware diseñado para recopilar datos del Sitio o los Servicios;
            </p>
          </li>
          <li>
            <p>
              recopilar cualquier información y/datos personales de otros
              Usuarios.
            </p>
          </li>
          <li>
            <p>
              interferir o interrumpir en la correcta prestación de los
              Servicios, el acceso de los usuarios, los servidores o redes que
              proporcionan los Servicios, o transmitir cualquier virus u otras
              instrucciones informáticas o medios tecnológicos cuyo propósito
              sea interrumpir, dañar o interferir en el correcto uso de
              computadoras o sistemas relacionados;
            </p>
          </li>
          <li>
            <p>acechar, acosar o dañar a otra persona;</p>
          </li>
          <li>
            <p>
              suplantar la identidad de cualquier persona física o jurídica o
              realizar cualquier otra actividad fraudulenta similar o abusiva,
              como por ejemplo, phishing;
            </p>
          </li>
          <li>
            <p>
              utilizar cualquier medio para rastrear (crawling) o extraer
              (scraping) cualquier página Web contenida en el Sitio;
            </p>
          </li>
          <li>
            <p>
              investigar, escanear, descifrar, rastrear y/o poner a prueba la
              vulnerabilidad de cualquier sistema o red, intentar eludir
              cualquier medida tecnológica o medida de autenticación
              implementada por nosotros, por nuestros proveedores o por terceros
              (incluidos otros usuarios) destinada a proteger el Sitio o
              nuestros Servicios;
            </p>
          </li>
          <li>
            <p>
              acceder o buscar, o intentar acceder o buscar los Servicios
              mediante cualquier medio (automatizado o de otro modo) que no sea
              a través de las interfaces publicadas por nosotros;
            </p>
          </li>
          <li>
            <p>
              intentar descifrar, descompilar, desmontar o realizar ingeniería
              inversa de cualquier software u otro código subyacente utilizado
              para proporcionar el Sitio o los Servicios;
            </p>
          </li>
          <li>
            <p>
              utilizar los Servicios de cualquier otra forma no permitida por
              estos Términos; o
            </p>
          </li>
          <li>
            <p>
              abogar, incitar o ayudar a cualquier tercero a realizar cualquiera
              de las acciones mencionadas anteriormente.
            </p>
          </li>
        </ul>
        <h3>
          <li>CONTENIDO EN NUESTRO SITIO Y SERVICIOS</li>
        </h3>
        <p>
          A los efectos de estos Términos: (a) "Contenido" hace referencia a:
          textos, gráficos, fotos, sonidos, música, vídeos, combinaciones
          audiovisuales, aplicaciones interactivas, software, secuencia de
          comandos (script), obras de autoría de cualquier tipo e información u
          otros materiales que sean publicados, generados, proporcionados o, a
          través de cualquier otra manera, puestos a disposición a través de los
          Servicios; (b) "Contenido de Usuario" hace referencia a: cualquier
          Contenido que los Usuarios (incluido usted) compartan, comuniquen,
          carguen o pongan a disposición a través de los Servicios. El Contenido
          de Usuario incluye las comunicaciones con otros usuarios y con
          nosotros, vínculos, información personal, imágenes, vídeos e
          información proporcionada por los usuarios en perfiles de usuarios.
        </p>
        <ol type="a">
          <li>
            <p>
              El uso del Sitio, los Servicios y el Contenido por parte del
              usuario se realiza bajo su propio riesgo.
            </p>
            <p>
              El Contenido de Usuario, ya sea compartido públicamente o
              transmitido de forma privada, es responsabilidad exclusiva de los
              Usuarios que originaron dicho Contenido de Usuario. Aunque podamos
              revisar el Contenido de Usuario, no estamos obligados a hacerlo.
              Asimismo, no nos comprometemos a revisar todo el Contenido de los
              Usuarios antes de que se distribuya a través del Sitio o de los
              Servicios, y no podemos asegurar la eliminación inmediata de
              Contenido de Usuario que sea cuestionable una vez que se ha
              publicado o distribuido.
            </p>
            <p>
              El usuario comprende que mediante el uso de nuestro Sitio y
              Servicios puede estar expuesto a Contenido de Usuario que puede
              ser indecente, ofensivo, dañino, impreciso, cuestionable, o, en
              definitiva, inapropiado de otra manera o perjudicial para
              terceros. No respaldamos, apoyamos, representamos ni garantizamos
              la calidad, integridad, veracidad, exactitud o fiabilidad de
              ningún Contenido de Usuario, ni comunicaciones ni respaldamos
              ninguna de las opiniones expresadas a través del Sitio y los
              Servicios.
            </p>
            <p>
              El usuario acepta que el uso del Sitio y los Servicios se realiza
              bajo su propio riesgo y responsabilidad. Como se indica más
              detalladamente en la Cláusula 13, bajo ninguna circunstancia
              seremos responsables de ninguna manera por cualquier Contenido o
              Contenido de Usuario publicado o establecido en nuestro Sitio o
              Servicios, cualquier error u omisión en cualquier Contenido, o
              cualquier pérdida o daño de cualquier tipo incurrido como
              resultado de la visualización, transmisión, uso o dependencia de
              cualquier Contenido en nuestro Sitio o Servicios o en cualquier
              otro lugar, o cualquier Contenido que pueda ser considerado
              indecente, ofensivo, dañino, impreciso, cuestionable o inapropiado
              de otra forma, mal etiquetado o engañoso de otra forma.
            </p>
            <p>
              Vigilante Renfe tiene el derecho de advertir a los Usuarios que el
              Contenido de los Usuarios no está moderado. Trabajaremos con
              nuestros Usuarios para hacer cumplir estos Términos, lo que
              incluye las disposiciones de Uso Apropiado descritas
              anteriormente, y la eliminación de contenido inapropiado o
              cuestionable. Nos reservamos el derecho de eliminar cualquier
              Contenido de los Usuarios por cualquier motivo a nuestra entera
              discreción.
            </p>
          </li>
          <li>
            <p>
              El Contenido de Usuario producido por usted y los derechos que nos
              concede
            </p>
            <p>
              El Sitio y algunos de nuestros Servicios permiten al Usuario
              sugerir, almacenar, enviar, publicar, recibir o cargar en el Sitio
              o en los Servicios Contenido de Usuario El acceso a estas
              funciones puede estar sujeto a restricciones de edad.
            </p>
            <p>
              Con el mero almacenamiento, envío o carga del Contenido de Usuario
              al Sitio o a cualquiera de nuestros Servicios, el Usuario otorga a
              Vigilante Renfe una licencia no exclusiva, transferible,
              sublicenciable, sin regalías, indefinida, irrevocable, a título
              gratuito y universal, para el uso de los siguientes derechos de
              explotación sobre dicho Contenido de Usuario:
            </p>
          </li>
          <ol>
            <li>
              <p>Derecho de reproducción.</p>
            </li>
            <li>
              <p>Derecho de distribución.</p>
            </li>
            <li>
              <p>
                Derecho de comunicación pública en todas las modalidades
                contempladas en la normativa de propiedad intelectual.
              </p>
            </li>
            <li>
              <p>Derecho de transformación</p>
            </li>
          </ol>
          <p>
            Dicha licencia se concederá por el máximo plazo establecido en la
            legislación vigente aplicable para su explotación en todo el
            Universo, para su explotación, ya sea solo o combinado con otros
            Contenidos de Usuario, a través de cualquier medio, método o
            tecnología, ya sea que existan actualmente o que se creen en un
            futuro.
          </p>
          <p>
            Asimismo, dicha licencia incluye el derecho de sublicenciar el
            Contenido de Usuario y hacer que el Contenido enviado a o a través
            de los Servicios esté disponible para otras compañías,
            organizaciones o individuos que se asocien con Vigilante Renfe para
            la difusión, distribución o publicación de dicho Contenido en otros
            medios y/o servicios. Esta licencia también incluye el derecho de
            usar el Contenido de Usuario para proporcionar, promover y mejorar
            el Sitio y los Servicios, desarrollar nuevos servicios y distribuir
            su Contenido de Usuario. La licencia otorgada sobre el Contenido de
            Usuario no es exclusiva, lo que significa que el Usuario puede usar
            el Contenido de Usuario para sus propios fines o permitir que
            terceros usen su Contenido de Usuario para sus propósitos. No
            obstante, el Usuario no puede usar Obras Derivadas fuera del Sitio o
            Servicios sin el permiso expreso previo por escrito de Vigilante
            Renfe. Esta licencia está completamente pagada y libre de regalías,
            lo que significa que Nosotros y otras empresas, organizaciones o
            personas que se asocian con Nosotros, no debemos al Usuario dinero o
            compensación de ningún tipo en relación con el uso de su Contenido
            de Usuario. Podemos ejercer nuestros derechos bajo esta cesión en
            cualquier parte del mundo. Por último, esta licencia es perpetua e
            irrevocable, lo que significa que nuestros derechos bajo esta cesión
            continúan incluso luego del cese de uso del Sitio y los Servicios
            por parte del usuario.
          </p>
          <p>El usuario asegura que:</p>
          <ul>
            <li>
              <p>
                posee todos los derechos, incluidos los derechos de propiedad
                intelectual,sobre su Contenido de Usuario o, alternativamente,
                que tiene el derecho exclusivo para otorgar los derechos
                descritos anteriormente; y
              </p>
            </li>
            <li>
              <p>
                su Contenido de Usuario no infringe los derechos de propiedad
                intelectual, privacidad, publicidad u otros derechos legales de
                terceros.
              </p>
            </li>
          </ul>
          <p>
            El Usuario comprende y reconoce que es el único responsable de las
            obligaciones y/o responsabilidades que se pudieran derivar del
            Contenido de Usuario que proporcione, lo que incluye el uso de dicho
            Contenido de Usuario por parte de otros usuarios y terceros.
          </p>
          <p>
            No está permitido que el Usuario pueda hacer uso y/o explotar las
            obras transformadas producto del ejercicio del derecho de
            transformación sobre el Contenido de Usuario ya existente y/o
            Contenido ("Obras Derivadas"), fuera del Sitio o Servicios sin el
            permiso expreso, previo y por escrito de Vigilante Renfe.
          </p>
          <p>
            El Usuario comprende que su Contenido de Usuario puede ser
            difundido, distribuido o publicado por terceros y que, en caso de
            que no disponga de los correspondientes derechos para subir
            Contenido de Usuario para tal uso, podrá ser considerado responsable
            de los perjuicios que ocasione, manteniendo indemne a Vigilante
            Renfe de cualquier reclamación proveniente de terceros. Vigilante
            Renfe no asume responsabilidad alguna sobre ningún uso de Contenido
            de Usuario por parte de terceros, ni de cualquier uso de Vigilante
            Renfe conforme a estos Términos.
          </p>
          <p>
            Podemos rehusar aceptar o transmitir Contenido de Usuario por
            cualquier motivo a nuestra entera discreción. Asimismo podemos
            eliminar Contenido de Usuario del sitio o de los Servicios por
            cualquier motivo a nuestra entera discreción.
          </p>
          <p>
            El Usuario acepta que Vigilante Renfe no tiene ninguna obligación de
            monitorizar o proteger el Contenido de Usuario.
          </p>
          <li>
            <p>Propiedad de todos los demás contenidos</p>
            <p>
              Le informamos que somos titulares legítimos de todo derecho o
              tenemos la licencia, con respecto a (a) el Sitio y los Servicios,
              lo que incluye el software, texto, medios y cualquier otro
              Contenido disponible en el Sitio y los Servicios ("Nuestro
              Contenido"); y (b) nuestras marcas comerciales, logos, nombres de
              dominio y elementos marcarios ("Marcas"). El Sitio y los
              Servicios, Nuestro Contenido y Marcas están protegidos bajo la
              legislación de derechos de autor, marcas registradas y otras leyes
              de los Estados Unidos e internacionales. El Usuario no puede
              duplicar, copiar o reutilizar Nuestro Contenido, nuestras Marcas o
              cualquier parte de los elementos o conceptos de diseño visual,
              HTML/CSS o JavaScript sin el permiso expreso previo por escrito de
              Vigilante Renfe.
            </p>
          </li>
          <li>
            <p>Contenido publicitario</p>
            <p>
              El Sitio y los Servicios pueden incluir anuncios y otro tipo de
              información, que pueden estar dirigidos al contenido o información
              sobre los Servicios (lo que incluye la Información de Usuario),
              consultas realizadas a través de los Servicios, o cualquier otra
              información. Los tipos y el alcance de la publicidad de Vigilante
              Renfe en los Servicios están sujetos a cambios.
            </p>
            <p>
              El Usuario acepta que, en concepto de contraprestación por el uso
              del Sitio y los Servicios, Vigilante Renfe y/o terceras entidades
              vinculadas a ésta puedan incluir y divulgar publicidad, tanto
              propia como de terceros, en cualquier momento en el Sitio, en el
              Contenido de Usuario y/o en los Servicios.
            </p>
          </li>
          <li>
            <p>
              Eliminación de Contenido; Cooperación con el cumplimiento de la
              ley
            </p>
            <p>Tenemos derecho a:</p>
            <ul>
              <li>
                <p>
                  eliminar o rehusar distribuir cualquier Contenido o Contenido
                  de Usuario a nuestra entera discreción;
                </p>
              </li>
              <li>
                <p>
                  emprender cualquier acción con respecto a cualquier Contenido
                  o Contenido de Usuario que consideremos necesaria o apropiada
                  a nuestra entera discreción, lo que incluye la toma de
                  acciones legales en aquellos supuestos en que creamos que
                  dicho Contenido de Usuario viola estos Términos de Servicio,
                  infringe cualquier derecho de propiedad intelectual u otro
                  derecho de cualquier persona física o jurídica, amenaza la
                  seguridad personal de los Usuarios, del Sitio o de los
                  Servicios, o del público, o que podría derivar
                  responsabilidades para Nosotros;
                </p>
              </li>
              <li>
                <p>
                  revelar la información de identificación personal del usuario
                  u otra información acerca de él a cualquier tercero que afirme
                  que el Contenido de Usuario proporcionado por el usuario viola
                  sus derechos, lo que incluye sus derechos de propiedad
                  intelectual o derechos de privacidad;
                </p>
              </li>
              <li>
                <p>
                  tomar las medidas legales apropiadas, lo que incluye, a modo
                  no taxativo, revelar información de identificación personal
                  del usuario u otra información acerca de él a organismos de
                  orden público como producto de cualquier uso ilegal,
                  sospechoso o no autorizado del Sitio o de los Servicios;
                </p>
              </li>
              <li>
                <p>
                  cancelar o suspender el acceso del usuario a todo o parte del
                  Sitio o los Servicios, y recuperar y redistribuir nombres de
                  usuario, por cualquier motivo, lo que incluye, a modo no
                  taxativo, la violación de estos Términos del Servicio.
                </p>
              </li>
              <li>
                <p>
                  Sin perjuicio de lo establecido anteriormente, estamos
                  legitimados para cooperar plenamente con cualquier organismo
                  de orden público u orden judicial que nos solicite o nos
                  ordene revelar la identidad u otra información acerca de
                  cualquier persona que publique o distribuya Contenido de
                  Usuario en o a través del Sitio o los Servicios. También nos
                  reservamos el derecho de acceder, revisar, monitorear,
                  exhibir, leer, conservar, almacenar y divulgar cualquier
                  información y cualquier Contenido de Usuario que creamos
                  razonablemente necesario o apropiado para: (i) cumplir
                  cualquier ley, regulación, proceso legal o solicitud
                  gubernamental aplicable; (ii) investigar posibles violaciones
                  y/o hacer cumplir estos Términos; (iii) detectar, prevenir, o
                  de otra manera abordar problemas de fraude, seguridad o
                  técnicos, (iv) responder a las solicitudes de asistencia a los
                  usuarios, o (v) proteger los derechos, propiedad o seguridad
                  de nuestros usuarios, nuestra compañía o terceros.
                </p>
              </li>
              <li>
                <p>
                  El usuario renuncia y exime de toda responsabilidad a nuestra
                  empresa (y a sus directivos, empleados, directores,
                  accionistas, matriz, filiales, agentes y licenciatarios
                  ("afiliados") de toda reclamación derivada del mal uso que
                  éste pudiera hacer del Sitio o de los Servicios ofertados por
                  Vigilante Renfe.
                </p>
              </li>
              <li>
                <p>
                  No asumimos ninguna responsabilidad por cualquier acción o
                  falta de acción con respecto a transmisiones, comunicaciones o
                  Contenido de Usuario proporcionado por cualquier usuario o
                  tercero a través del Sitio o de los Servicios.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <h3>
          <li>
            LICENCIA LIMITADA DEL USUARIO PARA EL SITIO, LOS SERVICIOS Y EL
            CONTENIDO
          </li>
        </h3>
        <p>
          Otorgamos al Usuario, sujeto y condicionado al cumplimiento de las
          Condiciones de Servicio, una licencia limitada, no exclusiva e
          intransferible para acceder y ver el Sitio, Contenido de Usuario y
          Nuestro Contenido únicamente en relación con su uso permitido, y
          únicamente para fines personales y no comerciales.
        </p>
        <p>
          El Usuario no puede descargar, capturar, guardar, distribuir ni
          retransmitir nuestro Contenido ni ningún otro Contenido de Usuario.
          Únicamente podrá descargar, capturar y/o guardar su Contenido de
          Usuario mediante la utilización de las herramientas que ponemos a
          disposición para tal fin a través del Sitio o de los Servicios. No
          obstante, no puede utilizar las Obras Derivadas de su Contenido de
          Usuario, tal como se define en la Cláusula 4.b, fuera del Sitio o de
          los Servicios sin permiso expreso previo por escrito de Vigilante
          Renfe.
        </p>
        <p>
          Asimismo, sujeto y condicionado al cumplimiento de las Condiciones de
          Servicio, otorgamos al Usuario una licencia limitada, no exclusiva, no
          transferible y no sublicenciable para descargar e instalar una copia
          de nuestro/s aplicación/es en un dispositivo móvil y para ejecutar
          tales copias de dicho/s aplicación/es exclusivamente para fines
          personales y no comerciales. Excepto en casos expresamente permitidos
          en estos Términos, el usuario no puede: (a) copiar, modificar o crear
          trabajos derivados basados ​​en la/las aplicación/es, Sitio o
          Servicios, (b) distribuir, transferir, sublicenciar, arrendar, prestar
          o alquilar la/las aplicación/es, Sitio o Servicios a terceros, (c)
          hacer ingeniería inversa, descompilar o desmontar la/las
          aplicación/es, Sitio o Servicios, o (d) poner a disposición de
          múltiples usuarios la funcionalidad de la/las aplicación/es, Sitio o
          Servicios a través de cualquier medio. Nos reservamos todos los
          derechos sobre la/s aplicación/es, el Sitio o los Servicios no
          expresamente otorgados al usuario según estos Términos.
        </p>
        <h3>
          <li>DERECHOS DE AUTOR Y POLÍTICA DE PROPIEDAD INTELECTUAL</li>
        </h3>
        <p>
          Respondemos a notificaciones de presunta infracción de derechos de
          autor y cancelamos cuentas de infractores reincidentes.
        </p>
        <p>
          Si el usuario cree que su trabajo ha sido copiado de una manera que
          constituye una infracción de derechos de autor, deberá contactarnos
          vía electrónica incluyendo la información que se encuentra a
          continuación:
        </p>
        <ul>
          <li>
            <p>
              Su dirección, número de teléfono y dirección de correo
              electrónico.
            </p>
          </li>
          <li>
            <p>
              Una descripción de la obra protegida por derechos de autor que
              alega que ha sido infringida.
            </p>
          </li>
          <li>
            <p>
              Una descripción de dónde está localizado el supuesto material
              infractor.
            </p>
          </li>
          <li>
            <p>
              Una declaración en la que manifiesta que cree de buena fe que el
              uso en disputa no está autorizado por el usuario, el dueño de los
              derechos de autor, su agente o la ley.
            </p>
          </li>
          <li>
            <p>
              Una firma electrónica o física de la persona autorizada para
              actuar en nombre del propietario de los derechos de autor.
            </p>
          </li>
          <li>
            <p>
              Una declaración del Usuario realizada bajo pena de perjurio, donde
              se establece que la información anterior es exacta y que el
              usuario es el propietario de los derechos de autor o está
              autorizado a actuar en nombre del propietario de los derechos de
              autor.
            </p>
            <p>
              Dicha información deberá ser enviada por vía electrónica al correo
              electrónico: hola@vigilanterenfe.es
            </p>
            <p>
              El Usuario reconoce que, si no cumple con todos los requisitos
              establecidos en la presente cláusula, su notificación puede no ser
              válida.
            </p>
            <p>
              Una vez recibida la notificación de infracción de derechos de
              autor, podremos remitirla a la parte que subió el contenido en
              cuestión. El Usuario debe tener en cuenta que, cuando enviemos su
              notificación de infracción de derechos de autor a la parte
              "infractora", dicha notificación incluirá la comunicación de su
              información y datos de carácter personal. Al enviar un aviso de
              infracción de derechos de autor, se entenderá que el Usuario
              presta su consentimiento para que dicha comunicación se efectúe.
            </p>
            <p>
              Si el Usuario considera que el contenido que se eliminó (o cuyo
              acceso se ha inhabilitado) no infringe ningún derecho de autor, o
              está autorizado por el propietario de los derechos de autor, del
              agente del propietario de los derechos de autor, o por la
              legislación para publicar y utilizar dicho contenido, podrá
              presentar un contra aviso a la dirección de correo electrónico
              citada anteriormente que contenga la siguiente información:
            </p>
          </li>
          <li>
            <p>Su firma física o electrónica;</p>
          </li>
          <li>
            <p>
              Identificación del contenido que se ha eliminado o cuyo acceso se
              ha inhabilitado y la ubicación en la que apareció el contenido
              previo a su eliminación o inhabilitación;
            </p>
          </li>
          <li>
            <p>
              Una declaración de que cree de buena fe que el contenido fue
              eliminado o inhabilitado como resultado de un error o una
              identificación errónea del contenido; y
            </p>
          </li>
          <li>
            <p>
              Su nombre, domicilio, número de teléfono y dirección de correo
              electrónico.
            </p>
            <p>
              Una vez recibido su contra aviso, daremos traslado del mismo a la
              parte que presentó la reclamación original de infracción de
              derechos de autor. El usuario deberá tener en cuenta que cuando
              enviamos el contra aviso, este incluirá su información y datos de
              carácter personal. Al enviar un contra aviso, el usuario da su
              consentimiento para que de esta manera se revele su información.
              No enviaremos el contra aviso a ninguna parte que no sea el
              demandante original.
            </p>
            <p>
              Dentro de los 10 días siguientes desde el envío del referido
              contra aviso, el demandante deberá notificarnos que ha presentado
              reclamación judicial ante el organismo competente, con el fin de
              evitar que elUsuario se involucre en actividades infractoras
              relacionadas con el contenido que fue removido o inhabilitado. En
              caso de que no recibamos esta notificación en el referido plazo de
              10 días, podremos reintegrar el material denunciado en el Sitio o
              en los Servicios.
            </p>
          </li>
        </ul>
        <h3>
          <li>SEGURIDAD Y PROTECCIÓN DE LA INFORMACIÓN</li>
        </h3>
        <p>
          La privacidad de nuestros Usuarios es de gran importancia para
          Nosotros. Nuestra Política de Privacidad explica cómo recopilamos,
          usamos, protegemos y cuándo compartimos su información personal y
          otros datos con terceros.
        </p>
        <p>
          Al acceder y/o utilizar el Sitio o los Servicios, el Usuario acepta la
          recopilación, uso y transferencia de su información personal, tal como
          se establece en la Política de Privacidad, que forma parte de estas
          Condiciones de Uso. Por ello, le rogamos que con carácter previo al
          uso del Sitio o los Servicios, lea detenidamente nuestra Política de
          Privacidad.
        </p>
        <h3>
          <li>CUENTAS DE USUARIO</li>
        </h3>
        <p>
          Para acceder a algunas funciones del Sitio o de los Servicios, el
          Usuario debe crear una cuenta. No podrá usar la cuenta de otra persona
          sin permiso. Al crear su cuenta, el Usuario debe proporcionar
          información precisa y completa. El Usuario es el único responsable de
          la actividad que se produce en su cuenta, y debe mantener la
          contraseña de su cuenta de forma segura y preservar su
          confidencialidad.
        </p>
        <p>
          Vigilante Renfe lo anima a usar contraseñas seguras (contraseñas que
          incluyan una combinación de letras mayúsculas y minúsculas, números y
          símbolos).
        </p>
        <p>
          En caso de que el Usuario tenga conocimiento de cualquier
          incumplimiento o uso no autorizado de su cuenta, deberá notificarnos
          inmediatamente.
        </p>
        <p>
          Vigilante Renfe no se responsabiliza de las pérdidas causadas por el
          uso no autorizado de su cuenta. Sin embargo, el Usuario puede ser
          responsable de las pérdidas sufridas por Vigilante Renfe o terceros
          con motivo del indebido uso de la cuenta.
        </p>
        <h3>
          <li>ENLACES</li>
        </h3>
        <p>
          El Sitio y los Servicios pueden contener enlaces a otros sitios web y
          recursos en línea gestionados por terceros. Un enlace al sitio web de
          un tercero no significa que lo respaldemos o que estemos afiliados con
          él. No somos responsables de ningún daño o pérdida relacionada con el
          uso de cualquier sitio web de terceros. El usuario siempre debe leer
          los términos y condiciones y la política de privacidad de un sitio web
          de terceros antes de usarlo.
        </p>
        <h3>
          <li>CAMBIOS EN EL SITIO O LOS SERVICIOS</li>
        </h3>
        <p>
          Mejoramos y actualizamos el Sitio y los Servicios a menudo. Por ello,
          nos reservamos el derecho a cambiar o suspender el Sitio o los
          Servicios en cualquier momento, con o sin previo aviso.
        </p>
        <h3>
          <li>CANCELACIÓN Y PRESERVACIÓN DE RESPONSABILIDADES</li>
        </h3>
        <p>
          Vigilante Renfe se reserva el derecho de permitir el acceso al Sitio y
          a los Servicios únicamente a aquellos Usuarios que cumplan las
          condiciones de acceso previstas en el presente documento de Términos y
          Condiciones, y por cualquier otra condición que Vigilante Renfe pueda
          establecer, a su propia discreción, en el futuro, pudiéndose revocar
          y/o cancelar su permiso para acceder al Sitio y los Servicios de forma
          automática. Igualmente, se reserva el derecho de cancelar el derecho
          de acceso a cualquier Usuario del Sitio o los Servicios en cualquier
          momento, a su sola discreción.
        </p>
        <p>
          El Usuario puede finalizar este acuerdo legal con Vigilante Renfe en
          cualquier momento por cualquier motivo mediante la interrupción del
          uso de los Servicios y/o poniéndose en contacto con
          hola@vigilanterenfe.es para la eliminación de su cuenta.
        </p>
        <p>
          Las disposiciones de las cláusulas 3, 4, 6, 7 y 12 a 17 continuarán en
          vigor aún después del cierre de su cuenta y cualquier expiración o
          cancelación de las presentes Condiciones de Servicio,siendo el Usuario
          responsable por todas sus actividades durante el tiempo que utilizó
          los Servicios.
        </p>
        <h3>
          <li>
            DESCARGO DE RESPONSABILIDAD Y LIMITACIONES DE NUESTRA
            RESPONSABILIDAD
          </li>
        </h3>
        <p>
          EL USUARIO HACE USO DEL SITIO Y LOS SERVICIOS BAJO SU PROPIO RIESGO.
          El Sitio y los Servicios se proporcionan tal y como se presentan al
          Usuario y están operativos según la disponibilidad de los mismos en
          cada momento En la medida en que lo permita la legislación aplicable,
          nuestra empresa y sus directivos, empleados, directores, accionistas,
          matriz, filiales , agentes y licenciatarios ("afiliados") renuncian a
          todas las garantías, condiciones y representaciones de cualquier tipo,
          ya sean expresas, implícitas, estatutarias o de otro tipo, lo que
          incluye aquellas relacionadas con la comerciabilidad, adecuación a un
          propósito particular y la no infracción, y las que surjan después del
          transcurso de negociación o uso mercantil.
        </p>
        <p>
          En particular, nuestra empresa y sus afiliados no realizan
          representaciones o garantías sobre la exactitud o integridad,
          oportunidad, seguridad o confiabilidad del Sitio, Servicios o
          cualquier contenido disponible en o a través del Sitio o de los
          Servicios, o el contenido de cualquier sitio web o servicios en línea
          vinculado o integrado al Sitio o los Servicios.
        </p>
        <p>
          Nuestra empresa y sus afiliados no tendrán ninguna responsabilidad por
          cualquier: (a) error, equivocación o inexactitud de contenido; (b)
          lesiones personales, daños a la propiedad o pérdida de datos
          resultantes del acceso o uso del Sitio o de los Servicios por parte
          del Usuario; (c) cualquier acceso no autorizado o uso de nuestros
          servidores o de cualquier información personal o datos del Usuario;
          (d) cualquier interrupción de transmisión del o desde el Sitio o los
          Servicios, o cualquier eliminación o falla en el almacenamiento o
          transmisión de cualquier contenido o comunicaciones; (e) cualquier
          error, virus, troyanos o similares que puedan transmitirse en o a
          través del Sitio o de los Servicios por cualquier tercero; (f) la
          incapacidad del Sitio o de los Servicios de satisfacer las
          expectativas o requerimientos del Usuario; o (g) cualquier pérdida o
          daño de cualquier tipo que se produzca como resultado del uso de
          cualquier contenido publicado o compartido a través del Sitio o de los
          Servicios.
        </p>
        <p>
          El Usuario comprende y acepta que cualquier material o información
          descargada u obtenida de otra manera a través del uso del Sitio o de
          los Servicios se realiza bajo su propio riesgo, y que será el único
          responsable de cualquier daño que derive de ello.
        </p>
        <p>
          Ninguna sugerencia o información, ya sea de forma oral o escrita,
          obtenida por el Usuario de Nosotros, o a través del Sitio o los
          Servicios conformará garantía alguna no expresamente formulada en
          estos términos.
        </p>
        <p>
          Renuncia de responsabilidad sobre daños consecuentes. En la medida en
          que lo permita la legislación aplicable, en ningún caso seremos
          responsables ante el Usuario o cualquier tercero por daños y
          perjuicios indirectos, especiales, incidentales, punitivos o
          consecuentes (lo que incluye la pérdida de ganancias, ingresos o
          datos), o por el costo de obtener productos o servicios sustitutivos
          que surjan de o en relación a estas Condiciones de Servicio, el Sitio
          o los Servicios, de cualquier manera que sean causados, ya sea que
          dicha responsabilidad surja de cualquier reclamación basada en un
          contrato, garantía, acuerdo extracontractual, responsabilidad
          objetiva, o de otra manera, así hayamos sido notificados o no acerca
          de la posibilidad de tales daños y perjuicios.
        </p>
        <p>
          Limitación de la responsabilidad total. En la medida en que lo permita
          la legislación aplicable, nuestra responsabilidad total acumulativa
          con respecto al Usuario o cualquier tercero que surja de o en relación
          a estas Condiciones de Servicio, el Sitio o los Servicios, de todas
          las causas de acción y todas las teorías de responsabilidad (lo que
          incluye contrato, garantía, acuerdo extracontractual, responsabilidad
          objetiva o cualquier otro), estará limitada a y no excederá las
          tarifas que el Usuario haya efectivamente pagado durante los 6 meses
          anteriores a la reclamación donde se generó esta responsabilidad,
          hasta un máximo de 100 dólares estadounidenses.
        </p>
        <p>
          Base de negociación. El Usuario comprende y acepta que hemos
          establecido nuestros precios y acordado estas Condiciones de Servicio
          con él con base en las limitaciones de responsabilidad establecidas en
          las presentes Condiciones de Servicio , las cuales adjudican el riesgo
          entre el Usuario y Nosotros y forman la base de un acuerdo entre las
          partes.
        </p>
        <p>
          Algunas jurisdicciones no permiten la exclusión de ciertas garantías o
          la limitación o exclusión de responsabilidad por daños incidentales o
          consecuentes. En consecuencia, es posible que algunas de las
          limitaciones y descargos de responsabilidad mencionados anteriormente
          no se apliquen al Usuario. En la medida en que no podamos, conforme a
          la legislación aplicable, renunciar a cualquier garantía implícita o
          limitar nuestras responsabilidades, el alcance y duración de dicha
          garantía y el alcance de nuestra responsabilidad será el mínimo
          previsto por dicha legislación.
        </p>
        <h3>
          <li>INDEMNIDAD</li>
        </h3>
        <p>
          El Usuario acepta indemnizar y eximir de responsabilidad a nuestra
          compañía y a sus Afiliados de y contra cualquier reclamación, costo,
          procedimiento, demanda, pérdida, daño, perjuicios y gastos (lo que
          incluye, a modo no taxativo, honorarios razonables de abogados y
          costos legales) de cualquier tipo o naturaleza, que surja o se
          relacione con cualquier violación real o supuesta de estas Condiciones
          de Servicio por el Usuario o cualquier persona que utilice su cuenta o
          su Contenido de Usuario. Si asumimos la defensa de tal asunto, el
          Usuario cooperará razonablemente con Nosotros en tal defensa.
        </p>
        <h3>
          <li>
            PARA USUARIOS QUE SE ENCUENTREN EN EUROPA - ARBITRAJE VINCULANTE
          </li>
        </h3>
        <p>
          Las presentes disposiciones se aplican al Usuario cuando éste tenga su
          domicilio y/o acceda, descargue y use nuestros Servicios en cualquier
          Estado Miembro de la Unión Europea, y en la medida en que su
          jurisdicción lo permita.
        </p>
        <p>
          El Usuario y Vigilante Renfe están de acuerdo en que cualquier
          controversia, diferencia o reclamación que surja del presente contrato
          y de toda enmienda al mismo o relativa al presente contrato,
          incluyendo en particular, su formación, validez, obligatoriedad,
          interpretación, ejecución, incumplimiento o terminación, así como las
          reclamaciones extracontractuales, serán sometidas a arbitraje
          vinculante y obligatorio para su solución definitiva de conformidad
          con el Reglamento de Arbitraje de la OMPI. El tribunal arbitral estará
          compuesto por un árbitro único. El arbitraje tendrá lugar en España.
          El idioma que se utilizará en el procedimiento arbitral será el
          español. La controversia, diferencia o reclamación se resolverá de
          conformidad con el derecho de España.
        </p>
        <p>
          A fin de evitar cualquier duda, el arbitraje es un mecanismo
          alternativo de resolución de conflictos, que sustituye al sistema
          judicial tradicional. En el arbitraje quien resuelve las controversias
          es un árbitro y no un juez, mediante un laudo arbitral, de obligado
          cumplimiento para las partes.
        </p>
        <p>
          Este procedimiento es más informal que una acción legal ante un
          tribunal y ofrece mayores ventajas, tales como mayor rapidez en la
          resolución del conflicto y garantiza la confidencialidad de la
          disputa, pues se trata de un proceso privado en el que los laudos no
          gozan de publicidad, a diferencia de las sentencias judiciales.
        </p>
        <h3>
          <li>OTRAS DISPOSICIONES</li>
        </h3>
        <p>
          Bajo ninguna circunstancia seremos responsables por cualquier retraso
          o fallo en el desempeño debido total o parcialmente por desastre
          natural u otras causas razonablemente fuera de nuestro control.
        </p>
        <p>
          Si cualquier disposición de estas Condiciones se considera ilegal o
          nula, entonces dicha disposición se considerará no aplicable, sin
          afectar la aplicabilidad de otras disposiciones de estos Términos.
        </p>
        <p>
          El hecho de que no podamos hacer cumplir algún derecho o disposición
          de estos Términos no nos impedirá hacer cumplir tal derecho o
          disposición en el futuro.
        </p>
        <p>
          Podemos ceder nuestros derechos y obligaciones bajo estas Condiciones,
          incluso en relación con una fusión, adquisición, venta de activos o
          patrimonio, o por aplicación de la legislación.
        </p>
        <h3>
          <li>CUOTAS DE TERCEROS</li>
        </h3>
        <p>
          El Usuario puede incurrir en gastos de acceso o datos de terceros
          (tales como su proveedor de Internet o su proveedor de servicios
          móviles) en relación con su uso del Sitio, los Servicios o el
          Contenido. El Usuario es responsable de todos estos cargos.
        </p>
        <h3>
          <li>CAMBIOS A ESTOS TÉRMINOS</li>
        </h3>
        <p>
          De vez en cuando podemos cambiar estos Términos y Condiciones de
          Servicio. Si cambiamos estos Términos, notificaremos al Usuario
          mediante la publicación de los Términos revisados en el Sitio. Dichos
          cambios entrarán en vigor en la fecha de revisión que se muestra en
          los Términos actualizados. Al continuar utilizando el Sitio o los
          Servicios, el Usuario está aceptando los Términos actualizados.
        </p>
        <p>
          ROGAMOS AL USUARIO QUE IMPRIMA UNA COPIA DE ESTOS TÉRMINOS PARA SUS
          ARCHIVOS Y COMPRUEBE EL SITIO CON FRECUENCIA POR CUALQUIER
          MODIFICACIÓN A ESTOS TÉRMINOS.
        </p>
        <h3>
          <li>CONTACTO</li>
        </h3>
        <p>Vigilante Renfe</p>
        <ul>
          <li>
            <p>Correo electrónico: hola@vigilanterenfe.es</p>
          </li>
        </ul>
        <p>©2020 Vigilante Renfe. Todos los derechos reservados.</p>
      </ol>
    </section>
  </Layout>
);

export default TermsPage
